.intro{
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
}
.intro_data{
    width: 100%;
    align-self: center;
    text-align: center;
}

.intro_img{
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.intro_img img {
    border-radius: 50%;

}

@media screen and (min-width: 768px){
    .intro {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    
    .intro_img {
        width: 38%;
        margin-right: 1rem;
    }    
    
    .intro_data {
        width: 55%;
        margin-left: 1rem;
    }
}