@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*===== GOOGLE FONTS =====*/
/*===== VARIABLES CSS =====*/
:root{
  --header-height: 3rem;
  --font-semi: 600;
}

/*===== Colores =====*/
:root{
  --first-color: #4070F4;
  --second-color: #0E2431;
}

/*===== Fuente y tipografia =====*/
:root{
  --body-font: 'Poppins', sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px){
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
  }
}

/*===== Margenes =====*/
:root{
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  --mb-8: 4.5rem;
}

/*===== z index =====*/
:root{
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body{
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  color: #0E2431;
  color: var(--second-color);
}

h1,h2,h3,p{
  margin: 0;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

a{
  text-decoration: none;
}

img{
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== CLASS CSS ===== */
.section-title{
  position: relative;
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  color: #4070F4;
  color: var(--first-color);
  margin-top: 1rem;
  margin-top: var(--mb-2);
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
  text-align: center;
}
.section-title::after{
  position: absolute;
  content: "";
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}
.section{
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.bd-grid{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.l-header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(146,161,176,.15);
}

.main-container {
  margin-left: 2rem;
  margin-left: var(--mb-4);
  margin-right: 2rem;
  margin-right: var(--mb-4);
}

/*===== NAV =====*/
.nav{
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-weight: var(--font-semi);
}
@media screen and (max-width: 768px){
  .nav_menu{
    position: fixed;
    top: 3rem;
    top: var(--header-height);
    right: -100%;
    width: 80%;
    height: 100%;
    padding: 2rem;
    background-color: #0E2431;
    background-color: var(--second-color);
    transition: .5s;
  }
}
.nav_item{
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.nav_link{
  position: relative;
  color: #fff;
}
.nav_link:hover{
  position: relative;
}
.nav_link:hover::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 0.18rem;
  left: 0;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}
.nav_logo{
  color: #0E2431;
  color: var(--second-color);
}
.nav_toggle{
  color: #0E2431;
  color: var(--second-color);
  font-size: 1.5rem;
  cursor: pointer;
}

/*Active menu*/
.active::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 0.18rem;
  left: 0;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}

/*=== Show menu ===*/
.show{
  right: 0;
}

/*===== HOME =====*/
.home{
  height: calc(60vh - 3rem);
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.home_data{
  align-self: center;
}
.home_title{
  font-size: 2rem;
  font-size: var(--big-font-size);
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-5);
}
.home_title-color{
  color: #4070F4;
  color: var(--first-color);
}
/* .home_social{
  display: flex;
  flex-direction: column;
}
.home_social-icon{
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
}
.home_social-icon:hover{
  color: var(--first-color);
} */
.home_img{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 295px;
}

/*BUTTONS*/
.button{
  display: inline-block;
  background-color: #4070F4;
  background-color: var(--first-color);
  color: #fff;
  padding: .75rem 2.5rem;
  font-weight: 600;
  font-weight: var(--font-semi);
  border-radius: .5rem;
}
.button:hover{
  box-shadow: 0 10px 36px rgba(0,0,0,.15);
}

/* ===== ABOUT =====*/
.about__container{
  grid-row-gap: 2rem;
  row-gap: 2rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.about__subtitle{
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
}
.about__img{
  justify-self: center;
}
.about__img img{
  width: 200px;
  border-radius: .5rem;
}

/* ===== SKILLS =====*/
.skills__container{
  grid-row-gap: 2rem;
  row-gap: 2rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.skills__subtitle{
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
}
.skills__text{
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.skills__data{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-weight: var(--font-semi);
  padding: .5rem 1rem;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
  border-radius: .5rem;
  box-shadow: 0 4px 25px rgba(14,36,49,.15);
}
.skills__icon{
  font-size: 2rem;
  margin-right: 1rem;
  margin-right: var(--mb-2);
  color: #4070F4;
  color: var(--first-color);
}
.skills__names{
  display: flex;
  align-items: center;
}
.skills__bar{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #4070F4;
  background-color: var(--first-color);
  height: .25rem;
  border-radius: .5rem;
  z-index: -10;
  z-index: var(--z-back);
  width: 100%;
}
/* .skills__html{
  width: 95%;
}
.skills__css{
  width: 85%;
}
.skills__js{
  width: 65%;
}
.skills__ux{
  width: 85%;
} */
.skills__img{
  border-radius: .5rem;
}

/* ===== WORK =====*/
.work__container{
  grid-row-gap: 2rem;
  row-gap: 2rem;
}
.work__img{
  box-shadow: 0 4px 25px rgba(14,36,49,.15);
  border-radius: .5rem;
  overflow: hidden;
}
.work__img img{
  transition: 1s;
  cursor: pointer;
}
.work__img img:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* ===== CONTACT =====*/
.contact__input{
  width: 100%;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-weight: 600;
  font-weight: var(--font-semi);
  padding: 1rem;
  border-radius: .5rem;
  border: 1.5px solid #0E2431;
  border: 1.5px solid var(--second-color);
  outline: none;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.contact__button{
  display: block;
  border: none;
  outline: none;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  cursor: pointer;
  margin-left: auto;
}

/* ===== FOOTER =====*/
.footer{
  background-color: #0E2431;
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-weight: var(--font-semi);
  padding: 2rem 0;
  margin-top: 2rem;
}
.footer__title{
  font-size: 2rem;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
}
.footer__subtitle {
  font-size: 1rem;
  font-weight: 600;
}
.footer__socials {
  margin-top: 0.5rem;
}
.footer__social{
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.footer__icon{
  font-size: 1.5rem;
  color: #fff;
  margin: 0 1rem;
  margin: 0 var(--mb-2)
}

/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px){
  body{
    margin: 0;
  }
  .main-container {
    margin-left: 4.5rem;
    margin-left: var(--mb-8);
    margin-right: 4.5rem;
    margin-right: var(--mb-8);
  }
  .section{
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-title{
    margin-bottom: 3rem;
    margin-bottom: var(--mb-6);
  }
  .section-title::after{
    width: 80px;
    top: 3rem;
  }

  .nav{
    height: calc(3rem + 1rem);
    height: calc(var(--header-height) + 1rem);
  }
  .nav_list{
    display: flex;
    padding-top: 0;
  }
  .nav_item{
    margin-left: 3rem;
    margin-left: var(--mb-6);
    margin-bottom: 0;
  }
  .nav_toggle{
    display: none;
  }
  .nav_link{
    color: #0E2431;
    color: var(--second-color);
  }

  .about__container, .skills__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    text-align: left;
    text-align: initial;
  }
  .about__img img{
    width: 300px;
  }
  .work__container{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2,1fr);
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .contact__form{
    width: 360px;
  }
  .contact__container{
    justify-items: center;
  }
}

@media screen and (min-width: 1024px){
  .bd-grid{
    margin-left: auto;
    margin-right: auto;
  }
  .home_img{
    right: 10%;
  }
}
.about__img {
    margin-right: 1.5rem;
}

.about__text {
    margin-bottom: 1rem;
}

.about_description {
    width: 65%;
}

.about_btn {
    display: flex;
    justify-content: flex-start;
}

.button--flex {
    display: flex;
    justify-content: center;
}
.button_icon {
    margin-left: 0.5rem;
}

.about_info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
}

.about_info_box {
    width: 100%;
    background-color: #eee;
    padding: 0.75rem 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
}

.about_info_title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000aaa;
}
.about_info_name {
    font-size: 0.75rem;
}

.about_info_title,
.about_info_name {
    display: block;
    text-align: center;

}

@media screen and (max-width: 768px){
    .about__container{
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about_description{
        width: 100%;
        align-self: center;
        text-align: center;
    }

    .about__img {
        margin-right: 0;
    }

    .about__img img{
        width: 100%;
        height: auto;
    }

    .about_btn {
        justify-content: center;
    }
}
.intro{
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
}
.intro_data{
    width: 100%;
    align-self: center;
    text-align: center;
}

.intro_img{
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.intro_img img {
    border-radius: 50%;

}

@media screen and (min-width: 768px){
    .intro {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    
    .intro_img {
        width: 38%;
        margin-right: 1rem;
    }    
    
    .intro_data {
        width: 55%;
        margin-left: 1rem;
    }
}

.qualification_tabs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.qualification_button {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
}

.qualification_button:hover {
    color: #4070F4;
}

.qualification_icon {
    font-size: 1.5rem;
    margin-right: 0.25rem;
    margin-top: 5px;
}

.qualification_data {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    margin-bottom: 0.75rem;
}

.qualification-data-info {
    text-align: center;
}

.qualification_title {
    font-size: 1.25rem;
    font-weight: 600;   
}

.qualification_subtitle {
    display: inline-block;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
}

.qualification_calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.75rem;
    font-size: 0.85rem;
    color: #aaa;
}

.qualification_calendar i {
    margin-right: 0.5rem;
    font-size: 0.5rem;
}

.qualification_rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: #4070F4;
    border-radius: 50%;
}

.qualification_line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: #4070F4;
    -webkit-transform: translate(6px, -7px);
            transform: translate(6px, -7px);
}

/* .qualification-section [data-content] {
    display: none;
}

.qualification_active[data-content] {
    display: block;
} */
.skills-left {
    width: 45%;
}

.skills-right {
    width: 45%;
}

@media screen and (max-width: 768px){
    .skills__subtitle {
        text-align: center;
    }
    .skills__container {
        flex-direction: column;
    }

    .skills-left, .skills-right {
        width: 100%;
    }
}
.awards-section {
    
}

.publication_data {
    
}

.publication {
    padding-bottom: 1.25rem;
}

.publication_title {
    font-size: 1.25rem;
    font-weight: 600;   
}

.publication_subtitle {
    display: inline-block;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
}

.publication_calendar, .publication_link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.25rem;
    margin-right: 0.75rem;
    font-size: 0.85rem;
    color: #aaa;
}

.publication_calendar i {
    margin-right: 0.5rem;
    font-size: 0.5rem;
}

.publication_link i {
    margin-top: 0.25rem;
}

.publication_link a {
    margin-left: 0.5rem;
    /* margin-bottom: 0.25rem; */
    color: #4070F4;
}

.publication_footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
